
import * as t from '@root/src/store/chisai/GCB2/actionTypes'
import Vue, { Component, PropType } from 'vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import { Connector, Project } from '@/types/main'
import { EditMasterMode, EditMasterStep } from '@/types/GCB2'
import { AvgCheckReportMappedSetting } from '@/types/avgCheckReport'
import { EDIT_MASTER_TYPE } from '@/vars/GCB2'
import _, { isNil } from 'lodash'
import { replaceElementAtIndex } from '@/utils'
import NameAndDescriptionStep from '../components/SchemaSettingSteps/NameAndDescriptionStep.vue'
import SpinnerLoader from '@/components/SpinnerLoader.vue'
import { CONNECTOR_TYPES } from '@/vars/general'
import CategoryStep from '../components/SchemaSettingSteps/CategoryStep.vue'
import api from '@/api/api'
import StaffStep from '../components/SchemaSettingSteps/StaffStep.vue'

export default Vue.extend({
    components: {
        Breadcrumbs,
        SpinnerLoader,
        NameAndDescriptionStep,
        CategoryStep,
    },

    data: () => ({
        EDIT_MASTER_TYPE,
        loading: true as boolean,
        stepsIndexes: [] as number[] | number,
        activeStepsIndexes: [] as number[] | number,
        copyLoading: false as boolean,
        settingIdFromQuery: null as any,
        setting: {} as AvgCheckReportMappedSetting | Omit<AvgCheckReportMappedSetting, 'id'>,
        createOrEditLoading: false as boolean,
        mode: null as EditMasterMode | null,
        serviceList: [] as { id: string; name: string }[],
        productList: [] as { id: string; name: string }[],
        staffList: [] as { id: string; name: string }[],
        steps: [
            {
                id: 1,
                title: 'Название и описание',
                contentComponent: NameAndDescriptionStep,
                ref: 'setting-1',
                saved: false,
                saveBtnDisabled: false,
                props: {},
            },
            {
                id: 2,
                title: 'Основные услуги',
                contentComponent: CategoryStep,
                ref: 'setting-2',
                saved: false,
                saveBtnDisabled: false,
                props: {
                    category: 1,
                },
            },
            {
                id: 3,
                title: 'Дополнительные услуги',
                contentComponent: CategoryStep,
                ref: 'setting-3',
                saved: false,
                saveBtnDisabled: false,
                props: {
                    category: 2,
                },
            },
            {
                id: 4,
                title: 'Премиальные услуги',
                contentComponent: CategoryStep,
                ref: 'setting-4',
                saved: false,
                saveBtnDisabled: false,
                props: {
                    category: 3,
                },
            },
            {
                id: 5,
                title: 'Комплексная услуга',
                contentComponent: CategoryStep,
                ref: 'setting-5',
                saved: false,
                saveBtnDisabled: false,
                props: {
                    category: 4,
                },
            },
            {
                id: 6,
                title: 'Товары',
                contentComponent: CategoryStep,
                ref: 'setting-6',
                saved: false,
                saveBtnDisabled: false,
                props: {
                    category: 5,
                },
            },
            {
                id: 7,
                title: 'Сотрудники',
                contentComponent: StaffStep,
                ref: 'setting-7',
                saved: false,
                saveBtnDisabled: false,
                props: {},
            },
        ] as EditMasterStep[],
    }),
    watch: {
        stepsIndexes(): void {
            if (this.mode === EDIT_MASTER_TYPE.create) {
                this.activeStepsIndexes = Array.isArray(this.stepsIndexes)
                    ? [...this.stepsIndexes]
                    : [this.stepsIndexes].filter(el => !isNil(el))
            }
        },
        $route: {
            immediate: true,
            deep: true,
            handler() {
                this.mode = this.$route.query.mode as EditMasterMode
                this.settingIdFromQuery = this.$route.query.id
            },
        },
    },
    computed: {
        title(): string {
            return this.mode === EDIT_MASTER_TYPE.create ? 'Новый Отчет' : 'Редактирование'
        },
        expandDisabled(): boolean {
            return this.mode === EDIT_MASTER_TYPE.edit
        },
        panelsIsMultiplie(): boolean {
            return this.mode === EDIT_MASTER_TYPE.edit
        },
        project(): Project {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        botConnector(): Connector {
            return this.project.connectors.find(
                el => el.connectorType === CONNECTOR_TYPES.whatsappBasis
            ) as Connector
        },
        breadcrumbs(): Array<any> {
            return [
                {
                    text: 'ВАШИ ПРОЕКТЫ',
                    to: '/projects',
                },
                {
                    text: this.project.name,
                    to: `/project/${this.project.id}/`,
                },
                {
                    text: 'СХЕМЫ РАСЧЁТА',
                    to: `/project/${this.project.id}/GCB2/avgCheckReport?section=settings`,
                },
                {
                    text: this.mode === 'create' ? 'СОЗДАНИЕ' : 'РЕДАКТИРОВАНИЕ',
                    to: '',
                    disabled: true,
                },
            ]
        },

        savedSteps(): any[] {
            return this.steps.filter(el => el.saved)
        },
        availableStepsIds(): number[] {
            if (this.mode === EDIT_MASTER_TYPE.create) {
                const nextStepIndex =
                    this.steps.findIndex(el => el.id === this.savedSteps[this.savedSteps.length - 1]?.id) + 1
                const nextStep = this.steps[nextStepIndex]
                return [...this.savedSteps.map(el => el.id), nextStep?.id]
            } else {
                return this.steps.map(el => el.id)
            }
        },
        availableSteps(): EditMasterStep[] {
            return this.steps.filter(el => (el.showCondition ? el.showCondition(this.project) : true))
        },
        saveButtonDisabled(): boolean {
            return !Boolean(this.setting)
        },
    },
    methods: {
        async init() {
            this.loading = true
            if (!this.mode) {
                this.mode = EDIT_MASTER_TYPE.create as EditMasterMode
                this.$router.push({
                    path: this.$router.currentRoute.path,
                    query: Object.assign({}, this.$router.currentRoute.query, {
                        mode: EDIT_MASTER_TYPE.create,
                    }),
                })
            }
            if (this.mode === EDIT_MASTER_TYPE.create) {
                this.setting = {
                    projectId: this.project.id,
                    name: '',
                    description: '',
                    services: await api.avgCheckReport
                        .getMappedServices(this.project.id)
                        .then(res => res!.data),
                    staff: [],
                }
                this.stepsIndexes = 0
            }
            if (this.mode === EDIT_MASTER_TYPE.edit && this.settingIdFromQuery) {
                this.setting = await api.avgCheckReport
                    .getOneMappedSetting(this.project.id, this.settingIdFromQuery)
                    .then(res => res.data!)
                this.stepsIndexes = this.steps.map((el, i) => i)
            }
            const [serviceList, staffList, productList] = await Promise.all([
                api.gcb2.getServiceList({ projectId: this.project.id }).then(res =>
                    res!.data?.map(el => ({
                        id: el.id,
                        name:
                            el.name +
                            `${el.category ? ` (${el.category})` : ''} ` +
                            `${el.archive ? ' (архивная)' : ''}`,
                    }))
                ),
                api.gcb2.getRecodingStaffList({ projectId: this.project.id }).then(res => res!.data),
                api.gcb2.getProductList({ projectId: this.project.id }).then(res => res!.data),
            ])
            this.serviceList = serviceList!
            this.staffList = staffList!
            this.productList = productList!
            this.loading = false
        },
        saveStep(step: EditMasterStep, index: number) {
            this.steps = replaceElementAtIndex(this.steps, index, Object.assign({}, step, { saved: true }))
            const nextStepIndex = index + 1
            this.stepsIndexes = nextStepIndex
            this.activeStepsIndexes = [nextStepIndex]
        },

        updateSaveButton(e: boolean, step: any, index: number) {
            this.steps = replaceElementAtIndex(
                this.steps,
                index,
                Object.assign({}, step, { saveBtnDisabled: !e })
            )
        },
        goToProject() {
            this.$router.push(`/project/${this.project.id}`)
        },
        cancel() {
            this.$router.push(`/project/${this.project.id}/GCB2/avgCheckReport?section=settings`)
        },
        async saveSetting() {
            this.createOrEditLoading = true
            const { data, error } = await api.avgCheckReport.saveMappedSetting({
                projectId: this.project.id,
                setting: this.setting,
            })
            if (error) {
                this.$store.dispatch('callNotify', 'Произошла ошибка')
                this.createOrEditLoading = false
                return
            }
            this.createOrEditLoading = false
            this.cancel()
        },

        stepIsActive(i: number) {
            if (Array.isArray(this.activeStepsIndexes)) return this.activeStepsIndexes.includes(i)
            else return [this.activeStepsIndexes].includes(i)
        },
        mapDataBeforeSave(setting: AvgCheckReportMappedSetting | Omit<AvgCheckReportMappedSetting, 'id'>) {
            return setting
        },
    },

    async created() {
        await this.init()
    },
    mounted() {},
})
